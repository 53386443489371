<template>
  <div id="CustomerQljgj">
    <!-- CustomerQljgj头部内容 -->
    <div class="CustomerQljgjHeader">
      <!-- 头部组件 -->
      <Header />
    </div>
    <!-- CustomerQljgj中间内容 -->
    <div class="CustomerQljgjCenter">
      <!-- 客户介绍 -->
      <div class="contentOne">
        <div class="titleOne">客户介绍</div>
        <div class="conInfo">
          <img src="~assets/img/CustomerQljgj/宁波群力紧固件@2x.png" />
          <div class="infoOne">
            宁波群力紧固件制造有限公司，座落于沿海现代化港口城市浙江宁波，占地面积40000㎡，专业生产各类不锈钢，
            高强度紧固件等各类汽车用和船用紧固件。年生产能力约25000余吨，产品注册商标为“群力”，识别标志为“QL”，
            产品按照DIN (德标)、 ANSI/ASTM(美标)、BS(英标)、 JIS
            (日标)、ISO(国际标准)、GB(国标)及汽车制造行业的相关标准
            和规范组织生产。产品广泛应用于汽车制造、大型重点工程建设、机械制造、建筑、船舶、桥梁、石化、冶金、环保等各行业。
          </div>
        </div>
      </div>
      <!-- 项目价值 -->
      <div class="contentTwo">
        <div class="titleTwo">项目价值</div>
        <div class="titleT">
          2020年7月17日，在镇海区工业互联网推进大会上，象来科技（宁波）有限公司与宁波群力紧固件制造有限公司签署数字化工厂项目合同。
          项目基于主要生产设备的数据采集与联网，打造了全过程的数字化管控系统，包括车间资源管理、生产任务管理、车间计划与排产管理、
          生产过程管理、质量过程管理、物料跟踪管理、车间监控管理、库存管理和统计分析等。
        </div>
        <ul class="conBot">
          <li class="con1" v-for="(item, index) in conBots" :key="index">
            <div class="icon"></div>
            <div class="title1">{{ item.Title }}</div>
            <div class="contentO">
              {{ item.Text }}
            </div>
          </li>
        </ul>
      </div>
      <!-- 项目概况 -->
      <div class="contentThree">
        <div class="titleThree">项目概况</div>
        <ul class="imgContent">
          <li v-for="(item, index) in imgContents" :key="index">
            <img :src="item.Imgs" />
            <div class="imgBottom">{{ item.textPro }}</div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 底部内容 -->
     <Footer />
     <!-- 回到顶部 -->
      <el-backtop
      ><img
        src="~assets/img/CustomerQljgj/icon-一键置顶@2x.png"
        alt="icon-回到顶部@2x.png"
    /></el-backtop>
  </div>
</template>
<script>
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
export default {
  name: "CustomerQljgj",
  data() {
    return {
      conBots: [
        {
          Title: "- 自动计算成本和交期",
          Text:
            "通过工厂数字化模型的建设达到自动成本计算、自动交期计算的目的，提高订单交付的响应速度和透明度。"
        },
        {
          Title: "- 提升效率和产出，缩减成本",
          Text:
            "利用MES（生产制造执行系统）的设备数据采集和工序报工，APS（高级计划和排程）实现满足客户交期（最高库存和安全库存）的前置条件下，瓶颈资源效率最大化、有效产出最佳化和生产成本的最小化。"
        },
        {
          Title: "- 数字化经营",
          Text:
            "通过数据平台，将经营和管理过程透明化、可视化，全景式、全时域地呈现工厂的运行状态，以数字化透明方式呈现经营过程和结果。"
        }
      ],
      imgContents: [
        {
          Imgs: require("assets/img/CustomerQljgj/概况-实景1.0@2x.png"),
          textPro: "宁波群力紧固件制造有限公司实景"
        },
        {
          Imgs: require("assets/img/CustomerQljgj/概况-2.0@2x.png"),
          textPro: "PDA扫码查询产品位置，货位的储存情况"
        },
        {
          Imgs: require("assets/img/CustomerQljgj/概况-3.0@2x.png"),
          textPro: "员工在工位电脑上查看工单信息"
        },
        {
          Imgs: require("assets/img/CustomerQljgj/概况4.0@2x.png"),
          textPro: "车间设备生产看板"
        },
        {
          Imgs: require("assets/img/CustomerQljgj/概况-5.0@2x.png"),
          textPro: "实现物料自动编码及BOM一站式建立"
        }
      ]
    };
  },
  components: {
    Header,
    Footer
  }
};
</script>
<style lang="less" scope>
#CustomerQljgj {
   width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;
  // CustomerQljgj头部样式
  .CustomerQljgjHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/CustomerQljgj/banner@2x.png") no-repeat;
    background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
  }
  // CustomerQljgj中间内容样式
  .CustomerQljgjCenter {
    width: 1220px;
    margin: 80px auto 101px;
    // 客户介绍
    .contentOne {
      .titleOne {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }
      .conInfo {
        margin-top: 30px;
        display: flex;
        img {
          width: 560px;
          height: 343px;
        }
        .infoOne {
          width: 598px;
          height: 157px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
          margin: 93px 0 0 40px;
        }
      }
    }
    // 项目价值
    .contentTwo {
      margin-top: 60px;
      .titleTwo {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }
      .titleT {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 28px;
        color: rgba(51, 51, 51, 0.85);
        margin-top: 30px;
      }
      .conBot {
        margin-top: 20px;
        padding: 0;
        li {
          margin-bottom: 20px;
          height: 110px;
          background-color: #fff;
          position: relative;
          border-radius: 10px;
          &:nth-child(2) {
            height: 140px;
          }
          .icon {
            width: 6px;
            height: 51px;
            background: #1e90ff;
            opacity: 1;
            border-radius: 0px 3px 3px 0px;
            display: inline-block;
            position: absolute;
            top: 32px;
          }

          .title1 {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 28px;
            color: #1e90ff;
            opacity: 1;
            display: inline-block;
            position: absolute;
            top: 20px;
            left: 20px;
          }

          .contentO {
            width: 1130px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 28px;
            color: #333333;
            opacity: 1;
            position: absolute;
            top: 60px;
            left: 20px;
          }
        }
      }
    }
    // 项目概况
    .contentThree {
      margin-top: 60px;
      .titleThree {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .imgContent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;
        padding: 0;
        li {
          width: 387px;
          height: 336px;
          margin-bottom: 20px;
          background-color: #fff;
          &:nth-child(4) {
            width: 588px;
            height: 336px;
            img {
              width: 588px;
              height: 288px;
            }
          }
          &:nth-child(5) {
              width: 593px;
            height: 336px;
            img {
            width: 593px;
              height: 288px;
            }
          }

          img {
            width: 387px;
            height: 290px;
          }
          .imgBottom {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.85);
            margin-top: 15px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-backtop{
    img{
        width: 30px;
        height: 30px;
    }
}
</style>
